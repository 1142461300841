import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    Box,
    ChakraProvider,
    Popover,
    PopoverTrigger,
    Button,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Center,
    SimpleGrid,
    extendTheme,
    Input,
    Heading
} from "@chakra-ui/react";

interface ColorPickerProps {
    id: string
    color: string,
    onChange: any
}

export const ColorPicker = ({id, color, onChange}: ColorPickerProps) => {
    // https://loading.io/color/feature/Spectral-10/
    // https://loading.io/color/feature/algolia/
    // https://loading.io/color/feature/

    const colors = [
        "#9e0142",
        "#d53e4f",
        "#f46d43",
        "#fdae61",
        "#fee08b",
        "#e6f598",
        "#abdda4",
        "#66c2a5",
        "#3288bd",
        "#5e4fa2",
        "#ffffff",
        "#cccccc",
        "#969696",
        "#636363",
        "#000000",
    ];

    return (
        <Box>
            <Center>
                <Popover variant="picker">
                    <PopoverTrigger>
                        <Button
                            aria-label={color}
                            background={color}
                            height="22px"
                            width="22px"
                            padding={0}
                            minWidth="unset"
                            border="2px"
                            borderColor={"blackAlpha.900"}
                            borderRadius={3}
                        ></Button>
                    </PopoverTrigger>
                    <PopoverContent width="170px">
                        <PopoverArrow bg={color} />
                        <PopoverCloseButton color="white" />
                        <PopoverHeader
                            height="100px"
                            backgroundColor={color}
                            borderTopLeftRadius={5}
                            borderTopRightRadius={5}
                            color="white"
                        >
                            <Center height="100%">{color}</Center>
                        </PopoverHeader>
                        <PopoverBody height="160px">
                            <SimpleGrid columns={5} spacing={2}>
                                {colors.map((c) => (
                                    <Button
                                        key={c}
                                        aria-label={c}
                                        background={c}
                                        height="22px"
                                        width="22px"
                                        padding={0}
                                        minWidth="unset"
                                        borderRadius={3}
                                        border="2px"
                                        borderColor={{ background: c }}
                                        _hover={{ background: c }}
                                        onClick={() => {
                                            onChange(id, c);
                                        }}
                                    ></Button>
                                ))}
                            </SimpleGrid>
                            <Input
                                borderRadius={3}
                                marginTop={3}
                                placeholder="red.100"
                                size="sm"
                                value={color}
                                onChange={(e) => {
                                    onChange(id, e.target.value);
                                }}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Center>
        </Box>
    );
}
