import React, { useState } from 'react';
import { Box, Text, Flex, useMediaQuery, Grid, Button } from "@chakra-ui/react";
import { ObjectID } from "bson";
import {Attendee} from "../../Models/Attendee";
import {AbEventAPI} from "../../APIs/AbEventAPI";

const eventDate = new Date("2024-06-06T18:10:00");

export const AttendeeCard = ({ attendee, onRsvp, onCheckIn }: { attendee: Attendee, onRsvp: () => void, onCheckIn: () => void }) => (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
        <Text fontSize="lg" fontWeight="bold">{attendee.full_name}</Text>
        <Text>Email: {attendee.email}</Text>
        <Text>Phone: {attendee.phone_number}</Text>
        <Text>RSVP: {attendee.rsvp ? 'Yes' : 'No'}</Text>
        <Text>Checked In: {attendee.checked_in ? 'Yes' : 'No'}</Text>
        <Flex mt={2}>
            <Button colorScheme={attendee.rsvp ? 'green' : 'red'} onClick={onRsvp} mr={2} isDisabled>
                {attendee.rsvp ? 'Cancel RSVP' : 'RSVP'}
            </Button>
            <Button colorScheme={attendee.checked_in ? 'green' : 'blue'} onClick={onCheckIn}>
                {attendee.checked_in ? 'Undo Check-In' : 'Check-In'}
            </Button>
        </Flex>
    </Box>
);

export const EventAttendeesList = () => {
    const [attendees, setAttendees] = useState<Attendee[]>([]);
    const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

    const handleRsvpToggle = (_id: string) => {
        setAttendees(attendees.map(attendee =>
            attendee._id === _id ? { ...attendee, rsvp: !attendee.rsvp } : attendee
        ));
    };

    const handleCheckInToggle = async (_id: string) => {
        const updatedAttendees = attendees.map(attendee =>
            attendee._id === _id ? { ...attendee, checked_in: !attendee.checked_in } : attendee
        );
        setAttendees(updatedAttendees);

        const updatedAttendee = updatedAttendees.find(attendee => attendee._id === _id);
        const checked_in = updatedAttendee?.checked_in;

        if (typeof checked_in !== 'undefined') {
            await AbEventAPI.check_in_attendee_by_id(_id, checked_in);
        } else {
            console.error('Failed to find the updated attendee.');
        }
    };

    const get_attendees = async () => {
        const res = await AbEventAPI.get_attendees()
        if (res) {
            setAttendees(res)
        }
    }

    const totalInvitees = attendees.length;
    const totalRsvp = attendees.filter(attendee => attendee.rsvp).length;
    const totalCheckedIn = attendees.filter(attendee => attendee.checked_in).length;

    return (
        <Box p={4}>
            <Text fontSize="2xl" mb={4}>Event Attendees</Text>
            <Text fontSize="md" mb={4}>Event Date: {eventDate.toLocaleString()}</Text>
            <Text fontSize="lg" mb={4}>Total Invitees: {totalInvitees}</Text>
            <Text fontSize="lg" mb={4}>Total RSVP: {totalRsvp}</Text>
            <Text fontSize="lg" mb={4}>Total Checked In: {totalCheckedIn}</Text>
            <Button onClick={get_attendees} mb={4} padding={4}>Refresh List</Button>
            {isLargerThan600 ? (
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {attendees.map(attendee => (
                        <AttendeeCard
                            key={attendee._id.toString()}
                            attendee={attendee}
                            onRsvp={() => handleRsvpToggle(attendee._id)}
                            onCheckIn={() => handleCheckInToggle(attendee._id)}
                        />
                    ))}
                </Grid>
            ) : (
                <Flex direction="column">
                    {attendees.map(attendee => (
                        <AttendeeCard
                            key={attendee._id.toString()}
                            attendee={attendee}
                            onRsvp={() => handleRsvpToggle(attendee._id)}
                            onCheckIn={() => handleCheckInToggle(attendee._id)}
                        />
                    ))}
                </Flex>
            )}
        </Box>
    );
};