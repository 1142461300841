import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
} from '@chakra-ui/react'
import * as React from "react";
import {useNavigate} from "react-router-dom";

export const SigninAndSignup = () => {
    let navigate = useNavigate();

    if (false) {
        return(
            <>

            </>
        )
    } else {
        return (
            <Stack
                flex={{ base: 1, md: 0 }}
                justify={'flex-end'}
                direction={'row'}
                spacing={6}>
                <Button
                    fontSize={'sm'}
                    fontWeight={400}
                    variant={'link'}
                    onClick={() => {
                        navigate("/signin")
                    }
                    }
                >
                    Sign In
                </Button>
                <Button
                    fontSize={'sm'}
                    fontWeight={600}
                    color={'white'}
                    onClick={() => {
                        navigate("/signup")
                    }}
                    bgGradient="linear(to-r, brand.300, brand.200)"
                    _hover={{
                        bg: "brand.200",
                    }}
                >
                    Sign Up
                </Button>
            </Stack>
        )
    }
}