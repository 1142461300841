import FLASK_HTTPS from "./FLASK_API";
import {ObjectID} from "bson";
import {User} from "../Models/User";

export namespace UsersAPI {

    let route_name = "/users"

    export const get_user_by_id = async (user_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/get_user_by_id/" + user_id.toString())
            .then((res) => {
                return res.data as User
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_user_by_google_uid = async (google_uid: string) => {
        return FLASK_HTTPS.get(route_name + "/get_user_by_google_uid/" + google_uid.toString())
            .then((res) => {
                return res.data as User
            })
            .catch((res) => {
                console.log(res)
            })
    }

    interface DoesUserExist{
        does_user_exist: boolean
    }

    export const does_user_exist_by_google_uid = async (google_uid: string) => {
        return FLASK_HTTPS.get(route_name + "/does_user_exist_by_google_uid/" + google_uid.toString())
            .then((res) => {
                return res.data as DoesUserExist
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_users = async () => {
        return FLASK_HTTPS.get(route_name + "/get_users")
            .then((res) => {
                return res.data as Array<User>
            }).catch((res) => {
                console.log(res)
            })
    }

    export const create_user = async (user: User) => {
        return FLASK_HTTPS.post(route_name + "/create_user", user)
            .then((res) => {
                return res.data as ObjectID
            }).catch((res) => {
                console.log(res)
            })
    }

}
