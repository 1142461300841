import {QrCode} from "../../Models/QrCode";
import QRCode from "qrcode.react";
import * as React from "react";
import {useState} from "react";
import {Box, Card, CardBody, Center, HStack, LightMode, Spacer, VStack} from "@chakra-ui/react";


interface Props {
    qrCode: QrCode
    size: number
    padding?: boolean
}

export const YazanQrCode = ({qrCode, size, padding}: Props) => {

    const [imageSettings, setImageSettings] = useState({
        src: "",
        height: 60,
        width: 60,
        excavate: true
    })

    if (padding) {
        return(
            <Center>
                <LightMode>
                    <Card
                        width={size}
                        height={size}
                    >
                        <CardBody
                            bgSize={"sm"}
                        >
                            <QRCode
                                id={qrCode._id.toString()}
                                size={size}
                                imageSettings={imageSettings}
                                bgColor={qrCode.bgColor}
                                fgColor={qrCode.qrColor}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrCode.value}
                                level={qrCode.errorCorrectionLevel}
                                includeMargin={true}
                            />
                        </CardBody>
                    </Card>
                </LightMode>
            </Center>
        )
    } else {
        return (
            <Center>
                <LightMode>
                    <Card
                        width={size}
                        height={size}
                    >

                        <QRCode
                            id={qrCode._id.toString()}
                            size={size}
                            imageSettings={imageSettings}
                            bgColor={qrCode.bgColor}
                            fgColor={qrCode.qrColor}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={qrCode.value}
                            level={qrCode.errorCorrectionLevel}
                            includeMargin={true}
                        />
                    </Card>
                </LightMode>
            </Center>
        )
    }


}