// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Auth products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Auth configuration
// For Auth JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCuXuK1SBrslfj6xRO6ovSYKHHYB-WByO8",
    authDomain: "texttoqrcode.firebaseapp.com",
    projectId: "texttoqrcode",
    storageBucket: "texttoqrcode.appspot.com",
    messagingSenderId: "112700331721",
    appId: "1:112700331721:web:2b44809b869c4a52be5f18",
    measurementId: "G-FV07GXGVES"
};

// Initialize Auth
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const FIREBASE_AUTH = getAuth(app);
