import React from "react"
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button, Spacer,
}
    from '@chakra-ui/react'
import {AddIcon} from "@chakra-ui/icons";

interface DialogProps {
    isOpen: boolean
    onClose: any
    cancelRef: any
    action: any
}

export const DeleteDialog = React.memo(({isOpen, onClose, cancelRef, action}: DialogProps) =>  {

    function preform_action() {
        action()
        onClose()
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Action!
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Spacer/>
                            <Button
                                bgGradient="linear(to-r, brand.300, brand.200)"
                                variant='solid'
                                onClick={preform_action}
                                _hover={{
                                    bg: "brand.200",
                                }}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
        </AlertDialog>
        </>
        )
    }
);
