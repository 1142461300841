import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack, Image,
    Input, InputGroup, InputRightElement, Link, Spacer,
    Stack,
    Text, useColorModeValue,
} from '@chakra-ui/react'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'
import * as React from "react";
import {ColorModeSwitcher} from "../Dashboard/ColorModeSwitcher";
import {useNavigate} from "react-router-dom";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {FirebaseAPI} from "../../Utils/Auth/FirebaseAPI";
import LocalStorageService from "../../Utils/Auth/LocalStorageService";
import {ObjectId} from "bson";

interface Props {
    route?: string
}

export const SmartSignIn = ({route}: Props) => {
    let navigate = useNavigate();

    const [isLoggedIn, SetIsLoggedIn] = useState<boolean>(false)

    useEffect(() => {
        FirebaseAPI.isUserSignedIn()
            .then((signedIn) => {
                    SetIsLoggedIn(signedIn as boolean)
                    if (signedIn) {
                        if (route) {
                            navigate(route)
                        } else {
                            navigate("/home")
                        }
                    }
                }
            )
        if (isLoggedIn) {
            if (route) {
                navigate(route)
            } else {
                navigate("/home")
            }
        }
    }, [isLoggedIn])


    return(
        <Container maxW="lg" py={{base: '12', md: '24'}} px={{base: '0', sm: '8'}}>
                <Box
                    height={"100%"}
                    py={{base: '0', sm: '8'}}
                    px={{base: '4', sm: '10'}}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={{sm: 'xl'}}
                    borderRadius={{sm: 'xl'}}
                >
                    <Stack>
                        <Spacer/>
                        <Stack align={'center'}>
                            <Heading fontSize={'4xl'} textAlign={'center'}>
                                { window.location.pathname.includes("signup")
                                    ? "Sign up"
                                    : "Sign in"
                                }
                            </Heading>
                            <Text fontSize={'lg'} color={'brand.200'}>
                                to enjoy all of our cool features ✌️
                            </Text>
                        </Stack>
                        <Spacer height={"15px"}></Spacer>
                        <Stack spacing="6">
                            <Stack spacing="6">
                                <HStack>
                                    <Divider/>
                                    <Divider/>
                                </HStack>
                                <OAuthButtonGroup/>
                            </Stack>
                        </Stack>
                        <Spacer/>
                    </Stack>
                </Box>
        </Container>
        )
}