import {
    Box,
    Heading,
    Center,
    Input,
    Spacer,
    VStack,
    Stack,
    CircularProgress,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text,
    HStack,
    LightMode,
    SimpleGrid,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    ModalBody, useDisclosure, FormControl, FormLabel, useToast, IconButton, Skeleton, Link, Badge

} from '@chakra-ui/react';
import {
    BiArrowBack,
    BiMinusBack,
    BiShare,
    CgShare,
    FaDiscord,
    FaGithub, FaShare,
    GiSpiralArrow,
    IoIosArrowBack
} from "react-icons/all";
import * as React from "react";
import {QrDesktopView} from "../../GenericQrCodeComponents/QrDesktopView";
import {QrMobileView} from "../../GenericQrCodeComponents/QrMobileView";
import {BrowserView, MobileView} from 'react-device-detect';
import {AddIcon, ArrowBackIcon, DeleteIcon, DownloadIcon, SettingsIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {QrProject} from "../../../Models/QrProject";
import {CustomTable1} from "../../Table/Table";
import {QrCodesAPI} from "../../../APIs/QrCodesAPI";
import LocalStorageService from "../../../Utils/Auth/LocalStorageService";
import {ObjectId} from "bson";
import {UsersAPI} from "../../../APIs/UsersAPI";
import {User} from "../../../Models/User";
import {DeleteDialog} from "../../Dialogs/DeleteDialog";
import {AddQrCode} from "./AddQrCode";
import {FirebaseAPI} from "../../../Utils/Auth/FirebaseAPI";
import logout = FirebaseAPI.logout;
import {useNavigate, useParams} from "react-router-dom";
import {QrCode} from "../../../Models/QrCode";
import {YazanQrCode} from "../../Utils/YazanQrCode";
import QRCode from "qrcode.react";
import {QrProjectsAPI} from "../../../APIs/QrProjectsAPI";
import {ShareProjectModal} from "./ShareProjectModal";

interface TableData {
    data: {
        qr_code: QrCode
        page_refresh: any
        link: string
    }
}

export const QrCodeProjectsDashboardDesktop = () => {

    let { project_id } = useParams();
    let navigate = useNavigate();

    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const [user, setUser] = useState<User>();
    const [qrProject, setQrProject] = useState<QrProject>();
    const [qrCodes, setQrCodes] = useState<Array<TableData>>();

    const get_user = () => {
        UsersAPI.get_user_by_id(LocalStorageService
            .getInstance().getUser()).then((res) => {
                let data = res!;
                setUser(data)
        })
    }

    const get_project = () => {
        QrProjectsAPI.get_qr_project_by_id(new ObjectId(project_id))
            .then((res) => {
                let data = res!
                setQrProject(data)
            })
    }

    const get_qr_code = () => {
        QrCodesAPI.get_qr_codes_by_project_id(new ObjectId(project_id!)).then((res) => {
            setQrCodes(undefined)
            let data = res!
            let entry = data.map((qr_code) => {
                return {
                    data: {
                        qr_code: qr_code,
                        page_refresh: refresh_page,
                        link: window.location.pathname + "/" + qr_code._id.toString(),
                    },
                }
            })
            console.log(entry)
            setQrCodes(entry)
        })
    }

    const refresh_page = () => {
        // get_qr_code()
        window.location.reload()
    }

    useEffect(() => {
        get_user()
        get_project()
        get_qr_code()
    }, [])

    return (
        <Box>
            <HStack>
                <Button
                    leftIcon={<IoIosArrowBack />}
                    variant='solid'
                    onClick={() => {
                        navigate("/SmartQrCode")
                    }}
                >
                    Projects Page
                </Button>
                <Spacer/>
                <Button
                    leftIcon={<FaShare />}
                    variant='solid'
                    onClick={onOpen1}
                >
                    Share Project
                </Button>
            </HStack>
            <Spacer height={"10px"}/>
            <HStack>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="lg"
                    fontFamily={""}
                    bgGradient="linear(to-r, brand.300, brand.200)"
                    backgroundClip="text"
                >
                    {qrProject?.name} Project Dashboard
                </Heading>
                <Spacer/>
                <Button
                    leftIcon={<AddIcon />}
                    bgGradient="linear(to-r, brand.300, brand.200)"
                    variant='solid'
                    onClick={onOpen2}
                    _hover={{
                        bg: "brand.200",
                    }}
                >
                    Create Qr Code
                </Button>
                <ShareProjectModal run={refresh_page} isOpen={isOpen1} onClose={onClose1}/>
                <AddQrCode run={refresh_page} isOpen={isOpen2} onClose={onClose2}/>
            </HStack>
            {qrCodes ?
                <CustomTable1 columns={qr_code_columns} data={qrCodes} hover_color={"brand.310"}/>
                :
                <Stack>
                    <Spacer/>
                    <HStack>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                    </HStack>
                    <Skeleton height='200px' width={"100%"}/>
                    <HStack>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                    </HStack>
                    <Skeleton height='100px' width={"100%"}/>
                </Stack>
            }
        </Box>
    );
}

const qr_code_columns = [
    {
        Header: "Qr Code",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link
                    onClick={() => {window.location.pathname = data.link}}
                >
                    <HStack>
                        <YazanQrCode qrCode={data.qr_code} size={50}/>
                        <Spacer/>
                    </HStack>
                </Link>
            )
        }
    },
    {
        Header: "Name",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link
                    onClick={() => {window.location.pathname = data.link}}
                >
                    <Badge
                        fontSize={"md"}
                    >
                        {data.qr_code.name}
                    </Badge>
                </Link>
            )
        }
    },
    {
        Header: "",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link onClick={() => {window.location.pathname = data.link}}/>
            )
        }
    },
    {
        Header: "",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link onClick={() => {window.location.pathname = data.link}}/>
            )
        }
    },
    {
        Header: "",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link onClick={() => {window.location.pathname = data.link}}/>
            )
        }
    },
    {
        Header: "Settings",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            return (
                <Box
                >
                    <IconButton
                        isDisabled
                        aria-label={"Settings"}
                        size={"sm"}
                        onClick={() => {}}
                    >
                        {<SettingsIcon />}
                    </IconButton>
                </Box>
            )
        },
    },
    {
        Header: "Delete",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            const { isOpen, onOpen, onClose } = useDisclosure()
            const cancelRef = React.useRef()

            const delete_action = () => {
                QrCodesAPI.delete_qr_code_by_id(data.qr_code._id)
                    .then(() => {
                        window.location.reload()
                    })
            }

            return (
                <Box
                >
                    <IconButton
                        aria-label={"Delete"}
                        size={"sm"}
                        bgColor='brand.300'
                        onClick={onOpen}
                        _hover={{
                            bg: "brand.200",
                        }}
                    >
                        {<DeleteIcon />}
                    </IconButton>
                    <DeleteDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} action={delete_action}/>
                </Box>
            )
        },
    },
]