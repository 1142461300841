import {ObjectId, ObjectID} from "bson";
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    User as FirebaseUser
} from "firebase/auth";


import {FIREBASE_AUTH} from "./Firebase";
import {UsersAPI} from "../../APIs/UsersAPI";
import {User} from "../../Models/User";
import LocalStorageService from "./LocalStorageService";

export namespace FirebaseAPI {

    const handle_backend_login = async (firebase_user: FirebaseUser) => {
        UsersAPI.does_user_exist_by_google_uid(firebase_user.uid)
            .then(async (res) => {
                let data = res!
                if (data.does_user_exist) {
                    LocalStorageService.getInstance().setToken(await firebase_user.getIdToken())
                    window.location.reload()
                } else {
                    LocalStorageService.getInstance().setToken(await firebase_user.getIdToken())
                    _create_user(firebase_user).then()
                    window.location.reload()
                }
            })
    }

    const _create_user = async (firebase_user: FirebaseUser) => {
        let user: User = {
            _id: new ObjectID(),
            google_uid: firebase_user.uid,
            profile: {
                first_name: firebase_user.displayName!.split(" ")[0],
                last_name: firebase_user.displayName!.split(" ")[1],
                email: firebase_user.email!
            }
        }
        UsersAPI.create_user(user).then()
    }

    const googleProvider = new GoogleAuthProvider();
    export const signInWithGoogle = async () => {
        signInWithPopup(FIREBASE_AUTH, googleProvider)
            .then((res) => {
                let user = res!.user
                handle_backend_login(user).then()
            });
    };

    export const logInWithEmailAndPassword = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(FIREBASE_AUTH, email, password);
        } catch (err) {
            console.error(err);
        }
    };

    export const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
        try {
            const res = await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
            const user = res.user;
            console.log(user)
        } catch (err) {
            console.error(err);
        }
    };

    export const sendPasswordReset = async (email: string) => {
        try {
            await sendPasswordResetEmail(FIREBASE_AUTH, email);
        } catch (err) {
            console.error(err);
        }
    };

    export const logout = () => {
        LocalStorageService.getInstance().clear()
        signOut(FIREBASE_AUTH).then();
        window.location.pathname="/h"
    };

    export const get_current_user = () => {
        const user = FIREBASE_AUTH.currentUser;

        if (user) {
            return user
        } else {
            return undefined
        }
    }

    export function isUserSignedIn(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            onAuthStateChanged(FIREBASE_AUTH, (user) => {
                if (user) {
                    UsersAPI.get_user_by_google_uid(user.uid)
                        .then(async (res) => {
                            let user_id: ObjectId = res!._id
                            LocalStorageService.getInstance().setUser(user_id)
                            resolve(true); // User is signed in
                        })
                } else {
                    resolve(false); // User is signed out
                }
            });
        });
    }

}
