import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon, Link,
  Avatar,
  chakra,
  Flex,
  SimpleGrid, Spacer, VStack, StackDivider,
} from '@chakra-ui/react';
import {FaDiscord, FaGithub} from "react-icons/all";
import * as React from "react";

export const AboutUs = () => {
    return (
      <>
        <header>
          <title>About Us</title>
        </header>

        <Container maxW={'3xl'}>
          <VStack as={Box} textAlign={'center'} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
            <Heading fontWeight={600} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>
              Text To Qr Code
              <br />
              <Text as={'span'} color={'brand.200'}>
                Generator
              </Text>
            </Heading>
            <Spacer></Spacer>
          <VStack>
            <Text color={'gray.500'}>
              <Heading>
                About us
              </Heading>
              <Spacer height={"20px"}/>
              <Text>
                Our QR code generator is a user-friendly web app that allows individuals and businesses to create and customize QR codes for their needs. Our platform is fast, easy to use, and generates high-quality QR codes for various use cases, including website links, contact information, and payment information. With our app, you can create unlimited QR codes for free and customize them. Our goal is to provide a reliable, efficient, and cost-effective solution for QR code generation to help you take your projects, personal indevours or business to the next level.
              </Text>
            </Text>
          </VStack>
          </VStack>
        </Container>
      </>
    );
}
