// EventPage.tsx
import React from 'react';
import { Box, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import EventQrScanner from "./EventQrScanner";
import {EventAttendeesList} from "./EventAttendeesList";

const EventPage = () => {
    return (
        <Box p={4}>
            <Text fontSize="2xl" mb={4}>Event Page</Text>
            <Tabs variant="soft-rounded" colorScheme="green">
                <TabList mb="1em">
                    <Tab>QR Scanner</Tab>
                    <Tab>Event Attendees</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <EventQrScanner />
                    </TabPanel>
                    <TabPanel>
                        <EventAttendeesList />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default EventPage;