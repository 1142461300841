import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    Heading,
    HStack,
    LightMode
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import {DownloadIcon} from "@chakra-ui/icons";
import * as React from "react";
import {QrCode} from "../../Models/QrCode";
import {useState} from "react";
import {YazanQrCode} from "../Utils/YazanQrCode";


interface QrCodeProps {
    qrCode: QrCode
    setQrCode: any
    qrCodeDownload: any,
}


export const QrCodeView = ({qrCode, setQrCode, qrCodeDownload}: QrCodeProps) => {

    const [imageSettings, setImageSettings] = useState({
        src: "",
        height: 60,
        width: 60,
        excavate: true
    })

    return (
        <>
            <Box flex={1}>
                <Card
                    height={"450px"}
                    width={"100%"}
                >
                    <CardHeader>
                        <Heading size='md'> Qr Code</Heading>
                    </CardHeader>
                    <CardBody>
                        <Center>
                            <YazanQrCode qrCode={qrCode} size={250} padding={true}/>
                        </Center>
                    </CardBody>
                    <CardFooter>
                        <Button
                            leftIcon={<DownloadIcon />}
                            bgGradient="linear(to-r, brand.300, brand.200)"
                            variant='solid'
                            onClick={qrCodeDownload}
                            _hover={{
                                bg: "brand.200",
                            }}
                        >
                            Download
                        </Button>
                    </CardFooter>
                </Card>
            </Box>
        </>
    )
}