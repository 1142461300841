import {Button, ButtonGroup, VisuallyHidden, VStack} from '@chakra-ui/react'
import { GitHubIcon, GoogleIcon, TwitterIcon, MicrosoftIcon} from './ProviderIcons'
import {FirebaseAPI} from "../../Utils/Auth/FirebaseAPI";
import {CgMicrosoft, FaMicrosoft, SiMicrosoft} from "react-icons/all";

const providers = [
    { name: 'Google', icon: <GoogleIcon boxSize="5" />, onClick: FirebaseAPI.signInWithGoogle },
    { name: 'Twitter', icon: <TwitterIcon boxSize="5" /> },
    { name: 'GitHub', icon: <GitHubIcon boxSize="5" /> },
    { name: 'Microsoft', icon: <SiMicrosoft/> },
]

export const OAuthButtonGroup = () => (
    <VStack spacing="4" width="full">
        {providers.map(({ name, icon, onClick }) => (
            <Button
                key={name}
                width="full"
                onClick={onClick}
            >
                <VisuallyHidden>Sign in with {name}</VisuallyHidden>
                {icon}
            </Button>
        ))}
    </VStack>
)