import {
    Box,
    Heading,
    Center,
    Input,
    Spacer,
    VStack,
    Stack,
    CircularProgress,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text, HStack, LightMode, SimpleGrid, Flex

} from '@chakra-ui/react';
import {FaDiscord, FaGithub} from "react-icons/all";
import * as React from "react";
import {QrDesktopView} from "../../GenericQrCodeComponents/QrDesktopView";
import {QrMobileView} from "../../GenericQrCodeComponents/QrMobileView";
import {BrowserView, MobileView} from 'react-device-detect';
import {QrCodeProjectsDashboardMobile} from "./QrCodeProjectsDashboardMobile";
import {QrCodeProjectsDashboardDesktop} from "./QrCodeProjectsDashboardDesktop";

export const QrCodeProjectDashboard = () => {

    return (
        <>
            <MobileView>
                <QrCodeProjectsDashboardDesktop/>
            </MobileView>
            <BrowserView>
                <QrCodeProjectsDashboardDesktop/>
            </BrowserView>
        </>
    );
}
