import {User} from "../../Models/User";
import {ObjectID, ObjectId} from "bson";
import {UsersAPI} from "../../APIs/UsersAPI";
import {FirebaseAPI} from "./FirebaseAPI";

class LocalStorageService {
    private static instance: LocalStorageService;

    private constructor() { 
        
    }
    public static getInstance(): LocalStorageService {
        if (!LocalStorageService.instance) {
            LocalStorageService.instance = new LocalStorageService();
        }
        return LocalStorageService.instance;
    }

    public setToken(access_token: string) {
        localStorage.setItem("access_token", access_token);
    }

    setUser(user_id: ObjectId) {
        localStorage.setItem("user_id", user_id.toString())
    }

    getUser(): ObjectId {
        return new ObjectId(localStorage.getItem("user_id")!)
    }

    public getAccessToken(): string | null {
        return localStorage.getItem("access_token");
    }

    public clear(){
        localStorage.clear()
    }
}

export default LocalStorageService;