import {
    Button, Progress,
    FormControl, FormLabel, HStack, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Spacer,
    useToast, VStack, Box
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {User} from "../../../Models/User";
import {QrProject} from "../../../Models/QrProject";
import {UsersAPI} from "../../../APIs/UsersAPI";
import LocalStorageService from "../../../Utils/Auth/LocalStorageService";
import {ObjectID, ObjectId} from "bson";
import {QrProjectsAPI} from "../../../APIs/QrProjectsAPI";
import * as React from "react";
import {readBlobText} from "@testing-library/user-event/utils/dataTransfer/Blob";
import {QrCode} from "../../../Models/QrCode";
import {QrCodesAPI} from "../../../APIs/QrCodesAPI";
import {useParams} from "react-router-dom";

interface Props {
    run: any,
    isOpen: any,
    onClose: any
}

export const AddQrCode = ({run, isOpen, onClose}: Props) => {

    let { project_id } = useParams();

    const toast = useToast()
    const [user, setUser] = useState<User>();
    const [newQrCode, setNewQrCode] = useState<QrCode>({
        _id: new ObjectID(),
        name: "",
        value: "",
        bgColor: "",
        qrColor: "",
        errorCorrectionLevel: "",
        project_id: new ObjectID(project_id),
    });
    const [loading, setLoading] = useState<boolean>(false);

    const update_qr_code_state = (name: string, value: any) => {
        setNewQrCode(
            (prevState: any) =>
                ({...prevState,
                    [name]: value})
        );
    }

    const get_user = async () => {
        setUser(await UsersAPI.get_user_by_id(LocalStorageService.getInstance().getUser()).then())
    }

    const clear_qr_code = () => {
        setLoading(false)
        setNewQrCode({
            _id: new ObjectID(),
            name: "",
            value: "",
            bgColor: "",
            qrColor: "",
            errorCorrectionLevel: "",
            project_id: new ObjectID(project_id),
    })
    }

    const required_info = () => {
        if (newQrCode?.name == "") {
            return false
        } else {
            return true
        }
    }

    const create_qr_code = () => {
        if (required_info()) {
            setLoading(true)
            QrCodesAPI.create_qr_code(newQrCode!)
                .then((res) => {
                    let data = res!
                    toast({
                        title: 'Success.',
                        description: newQrCode?.name + " Created Successfully",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    run()
                    window.location.pathname += "/" + data._id.toString()
                })
                .catch(() => {
                    setLoading(false)
                    toast({
                        title: 'Error.',
                        description: "Qr Code creation failed!",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        } else {
            toast({
                title: 'Error.',
                description: "Qr Code creation failed!",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const close_modal = () => {
        setLoading(false)
        clear_qr_code()
        onClose()
    }

    useEffect(() => {
        get_user().then()
    }, [])

    useEffect(() => {
        clear_qr_code()
    }, [user])

    return(
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create a new Qr Code</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack>
                            <FormControl
                                id="name"
                                isRequired
                            >
                                <FormLabel>Qr Code Name</FormLabel>
                                <Input
                                    placeholder='Discount Link'
                                    value={newQrCode?.name}
                                    onChange={(value) => {
                                        update_qr_code_state(value.target.id, value.target.value)
                                    }
                                    }
                                    autoFocus={true}/>
                            </FormControl>
                            <Spacer/>
                            { loading ?
                                <Box width={"100%"}>
                                    <Progress
                                        isIndeterminate={loading}
                                        colorScheme={"red"}
                                    />
                                </Box>
                                :
                                <></>
                            }
                        </VStack>
                    </ModalBody>

                    <ModalFooter>

                        <VStack>
                            <HStack>
                                <Spacer/>
                                <Button
                                    onClick={close_modal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    bgGradient="linear(to-r, brand.300, brand.200)"
                                    isDisabled={!required_info()}
                                    variant='solid'
                                    onClick={create_qr_code}
                                    isLoading={loading}
                                    _hover={{
                                        bg: "brand.200",
                                    }}
                                >
                                    Create Qr Code
                                </Button>
                            </HStack>
                        </VStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}