import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Button, Center, Icon, Text, VStack, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { ObjectId } from 'bson';
import { AbEventAPI } from "../../APIs/AbEventAPI";

interface ScannerResultPageProps {
    scannedData: string;
    onThisClose: () => void;
}

const ScannerResultPage: React.FC<ScannerResultPageProps> = ({ scannedData, onThisClose }) => {
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const handleResult = async () => {
            if (scannedData) {
                if (ObjectId.isValid(scannedData)) {
                    try {
                        const res = await AbEventAPI.check_in_attendee_by_id(scannedData, true);
                        setIsValid(typeof res === "boolean" ? res : false);
                    } catch (error) {
                        console.error("Error checking in attendee:", error);
                        setIsValid(false);
                    }
                } else {
                    setIsValid(false);
                }
                onOpen(); // Open modal to show results
            }
        };

        handleResult();
    }, [scannedData, onOpen]);

    const handleCloseModal = () => {
        setIsValid(undefined);
        onClose();  // Close modal
        onThisClose()
    };

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} size="full">
            <ModalOverlay />
            <ModalContent backgroundColor={isValid === undefined ? 'white' : (isValid ? 'green.500' : 'red.500')}>
                <ModalBody>
                    <Center height="100vh">
                        <VStack spacing={4}>
                            {isValid !== undefined && (
                                <Box textAlign="center" mt={4}>
                                    <Icon as={isValid ? CheckCircleIcon : CloseIcon} boxSize={12} color="white" />
                                    <Text fontSize="xl" mt={2} color="white">{scannedData}</Text>
                                </Box>
                            )}
                            <Button
                                colorScheme="white"
                                color={'white'}
                                variant="outline"
                                onClick={handleCloseModal}
                                mt={8}
                                size="lg"
                            >
                                Dismiss
                            </Button>
                        </VStack>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const EventQrScanner: React.FC = () => {
    const [data, setData] = useState<string>('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isScanning, setIsScanning] = useState(true);

    const handleResult = async (result: any) => {
        if (result && isScanning) {
            setIsScanning(false);
            const scannedData = result?.text;
            setData(scannedData);
            onOpen(); // Open modal to show results
        }
    };

    const handleCloseModal = () => {
        setIsScanning(true);  // Resume scanning when modal is closed
        onClose();  // Close modal
    };

    return (
        <Center height="50vh" bg={'gray.50'}>
            <VStack spacing={4} w={['90%', '70%', '50%']} p={4} borderRadius="md" boxShadow="md" bg="white" maxW="400px">
                <Box width="100%" height="300px" bg="gray.200" position="relative" borderRadius="md" overflow="hidden">
                    <QrReader
                        onResult={(result, error) => {
                            if (result && isScanning) handleResult(result).then();
                        }}
                        constraints={{ facingMode: 'environment' }}
                    />
                </Box>
            </VStack>
            {isOpen && <ScannerResultPage scannedData={data} onThisClose={handleCloseModal}/>}
        </Center>
    );
};

export default EventQrScanner;