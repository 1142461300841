import FLASK_HTTPS from "./FLASK_API";
import {ObjectID} from "bson";
import {QrProject} from "../Models/QrProject";
import {User} from "../Models/User";

export namespace QrProjectsAPI {

    let route_name = "/qr_projects"

    export const get_qr_project_by_id = async (qr_project_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/get_qr_project_by_id/" + qr_project_id.toString())
            .then((res) => {
                return res.data as QrProject
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_qr_projects_by_user_id = async (user: User) => {
        return FLASK_HTTPS.get(route_name + "/get_qr_projects_by_user_id/" + user._id.toString())
            .then((res) => {
                return res.data as Array<QrProject>
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const delete_qr_project_by_id = async (qr_project_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/delete_qr_project_by_id/" + qr_project_id.toString())
            .then((res) => {
                return res.data as boolean
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const create_qr_project = async (qr_project: QrProject) => {
        return FLASK_HTTPS.post(route_name + "/create_qr_project", qr_project)
            .then((res) => {
                return res.data as QrProject
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const share_qr_project = async (qr_project: QrProject, email: string) => {
        return FLASK_HTTPS.post(route_name + "/share_qr_project",
            {
                project_id: qr_project._id,
                email: email
            }
            )
            .then()
            .catch((res) => {
                console.log(res)
            })
    }

}


