import {
    Box,
    Heading,
    Center,
    Input,
    Spacer,
    VStack,
    Stack,
    CircularProgress,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text, HStack, LightMode, SimpleGrid, Flex

} from '@chakra-ui/react';
import {FaDiscord, FaGithub} from "react-icons/all";
import * as React from "react";
import {QrDesktopView} from "../../GenericQrCodeComponents/QrDesktopView";
import {QrMobileView} from "../../GenericQrCodeComponents/QrMobileView";
import {BrowserView, MobileView} from 'react-device-detect';
import {QrCodeProjectsTableMobile} from "./QrCodeProjectsTableMobile";
import {QrCodeProjectsTableDesktop} from "./QrCodeProjectsTableDesktop";

export const QrCodeProjects = () => {

    return (
        <>
            <MobileView>
                <QrCodeProjectsTableDesktop/>
            </MobileView>
            <BrowserView>
                <QrCodeProjectsTableDesktop/>
            </BrowserView>
        </>
    );
}
