import {
    Box,
    Heading,
    Center,
    Input,
    Spacer,
    VStack,
    Stack,
    CircularProgress,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text,
    HStack,
    LightMode,
    SimpleGrid,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    ModalBody, useDisclosure, FormControl, FormLabel, useToast, IconButton, Skeleton, Link, Badge, Tooltip

} from '@chakra-ui/react';
import {FaDiscord, FaGithub, IoIosArrowBack} from "react-icons/all";
import * as React from "react";
import {QrDesktopView} from "../../GenericQrCodeComponents/QrDesktopView";
import {QrMobileView} from "../../GenericQrCodeComponents/QrMobileView";
import {BrowserView, MobileView} from 'react-device-detect';
import {AddIcon, DeleteIcon, DownloadIcon, SettingsIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {QrProject} from "../../../Models/QrProject";
import {CustomTable1} from "../../Table/Table";
import {QrProjectsAPI} from "../../../APIs/QrProjectsAPI";
import LocalStorageService from "../../../Utils/Auth/LocalStorageService";
import {ObjectId} from "bson";
import {UsersAPI} from "../../../APIs/UsersAPI";
import {User} from "../../../Models/User";
import get_qr_project_by_id = QrProjectsAPI.get_qr_project_by_id;
import {DeleteDialog} from "../../Dialogs/DeleteDialog";
import {AddProjectModal} from "./AddProjectModal";
import {FirebaseAPI} from "../../../Utils/Auth/FirebaseAPI";
import logout = FirebaseAPI.logout;
import {QrCodesAPI} from "../../../APIs/QrCodesAPI";
import {useNavigate} from "react-router-dom";

interface TableData {
    data: {
        qr_project: QrProject
        page_refresh: any
        link: string
    }
}

export const QrCodeProjectsTableDesktop = () => {

    let navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [user, setUser] = useState<User>();
    const [qrProjects, setQrProjects] = useState<Array<TableData>>();

    const get_user = () => {
        UsersAPI.get_user_by_id(LocalStorageService.getInstance().getUser()).then((res) => {
            let data = res!;
            setUser(data)
        })
    }

    const get_qr_projects = () => {
        QrProjectsAPI.get_qr_projects_by_user_id(user!).then((res) => {
            setQrProjects(undefined)
            let data = res!
            let entry = data.map((qr_project) => {
                return {
                    data: {
                        qr_project: qr_project,
                        page_refresh: refresh_page,
                        link: window.location.pathname + "/" + qr_project._id.toString(),
                    },
                }
            })
            setQrProjects(entry)
        })
    }

    const refresh_page = () => {
        // get_qr_projects()
        window.location.reload()
    }

    useEffect(() => {
        get_user()
    }, [])

    useEffect(() => {
        get_qr_projects()
        console.log(qrProjects)
    }, [user])

    return (
        <Box>
            <HStack>
                <Button
                    leftIcon={<IoIosArrowBack />}
                    variant='solid'
                    onClick={() => {
                        navigate("/home")
                    }}
                >
                    Home Page
                </Button>
            </HStack>
            <Spacer height={"10px"}/>
            <HStack>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="lg"
                    fontFamily={""}
                    bgGradient="linear(to-r, brand.300, brand.200)"
                    backgroundClip="text">
                    Qr Code Projects
                </Heading>
                <Spacer/>
                <Button
                    leftIcon={<AddIcon />}
                    bgGradient="linear(to-r, brand.300, brand.200)"
                    variant='solid'
                    onClick={onOpen}
                    _hover={{
                        bg: "brand.200",
                    }}
                >
                    Create Project
                </Button>
                <AddProjectModal run={refresh_page} isOpen={isOpen} onClose={onClose}/>
            </HStack>
            {qrProjects ?
                <CustomTable1 columns={qr_projects_columns} data={qrProjects} hover_color={"brand.310"}/>
                :
                <Stack>
                    <Spacer/>
                    <HStack>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                    </HStack>
                    <Skeleton height='200px' width={"100%"}/>
                    <HStack>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                        <Skeleton height='100px' width={"100%"}/>
                    </HStack>
                    <Skeleton height='100px' width={"100%"}/>
                </Stack>
            }
        </Box>
    );
}

const qr_projects_columns = [
    {
        Header: "Qr Project Name",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link
                    onClick={() => {window.location.pathname = data.link}}
                >
                    <Text
                        fontSize={"md"}
                    >
                        {data.qr_project.name}
                    </Text>
                </Link>
            )
        }
    },
    {
        Header: "",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {
            return(
                <Link onClick={() => {window.location.pathname = data.link}}/>
            )
        }
    },
    {
        Header: "Number of Qr Codes",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            const [numberOfQrCode, setNumberOfQrCode] = useState<number>(0)

            const get_number_of_qr_code = () => {
                QrCodesAPI.get_qr_codes_by_project_id(data.qr_project._id)
                    .then((res)=> {
                        let data = res!
                        setNumberOfQrCode(data.length)
                    })
            }

            useEffect(() => {
               get_number_of_qr_code()
            }, [])

            if (numberOfQrCode===0) {
                return(
                    <Link onClick={() => {window.location.pathname = data.link}}>
                        <Badge>
                            {numberOfQrCode.toString()} Qr Codes
                        </Badge>
                    </Link>
                )
            } else {
                return(
                    <Link onClick={() => {window.location.pathname = data.link}}>
                        <Badge
                            colorScheme={"blue"}
                        >
                            {numberOfQrCode.toString()} Qr Codes
                        </Badge>
                    </Link>
                )
            }
        }
    },
    {
        Header: "Owner",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            const [owner, setOwner] = useState<User>()

            const get_number_of_qr_code = () => {
                UsersAPI.get_user_by_id(data.qr_project.owner)
                    .then((res)=> {
                        let data = res!
                        setOwner(data)
                    })
            }

            useEffect(() => {
                get_number_of_qr_code()
            }, [])

            return(
                <Link
                    onClick={() => {window.location.pathname = data.link}}
                >
                    <Badge
                        colorScheme={"green"}
                    >
                        {owner?.profile.first_name + " " + owner?.profile.last_name}
                    </Badge>
                </Link>
            )
        }
    },
    {
        Header: "Settings",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            return (
                <Box
                >
                    <IconButton
                        isDisabled
                        aria-label={"Settings"}
                        size={"sm"}
                        onClick={() => {}}
                    >
                        {<SettingsIcon />}
                    </IconButton>
                </Box>
            )
        },
    },
    {
        Header: "Delete",
        accessor: "data",
        Cell: function StatusCell(data: TableData["data"]) {

            const { isOpen, onOpen, onClose } = useDisclosure()
            const cancelRef = React.useRef()

            const [numberOfQrCode, setNumberOfQrCode] = useState<number>(0)

            const get_number_of_qr_code = () => {
                QrCodesAPI.get_qr_codes_by_project_id(data.qr_project._id)
                    .then((res)=> {
                        let data = res!
                        setNumberOfQrCode(data.length)
                    })
            }

            const delete_action = () => {
                QrProjectsAPI.delete_qr_project_by_id(data.qr_project._id)
                    .then(() => {
                        window.location.reload()
                    })
            }

            useEffect(() => {
                get_number_of_qr_code()
            }, [])

            return (
                <Box
                    width={"10px"}
                >
                    {numberOfQrCode == 0
                        ?
                        <IconButton
                            aria-label={"Delete"}
                            size={"sm"}
                            bgColor='brand.300'
                            onClick={onOpen}
                            _hover={{
                                bg: "brand.200",
                            }}
                        >
                            {<DeleteIcon />}
                        </IconButton>
                        :
                        <Tooltip label="Please delete all Qr Codes in collection to enable this functionality!">
                            <IconButton
                                isDisabled
                                aria-label={"Delete"}
                                size={"sm"}
                                bgColor='brand.300'
                                onClick={onOpen}
                                _hover={{
                                    bg: "brand.200",
                                }}
                            >
                                {<DeleteIcon />}
                            </IconButton>
                        </Tooltip>


                    }
                    <DeleteDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} action={delete_action}/>
                </Box>
            )
        },
    },
]