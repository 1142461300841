
import { NavigateFunction } from "react-router-dom";
import instance from "../../APIs/FLASK_API";
import LocalStorageService from "./LocalStorageService";
import {FirebaseAPI} from "./FirebaseAPI";

const localStorageService = LocalStorageService.getInstance();

const SetupInterceptors = (navigate: NavigateFunction) => {
    instance.interceptors.request.use(
        config => {
            const token = localStorageService.getAccessToken();
            if (token) {
                config.headers!['Authorization'] = `Bearer ${token}`;
            }
            else{
                localStorageService.clear()
            }
            return config;
        },
        error => {
            Promise.reject(error).then()
    });


    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            FirebaseAPI.logout()
            return Promise.reject(error);
        }
    );
};

export default SetupInterceptors;