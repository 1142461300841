import * as React from "react"
import {
    ChakraProvider, ColorModeScript, extendTheme,
} from "@chakra-ui/react"
import {
    BrowserRouter,
    Routes,
    Route,
    useNavigate, Navigate
} from "react-router-dom";
import { ColorModeSwitcher } from "../Dashboard/ColorModeSwitcher"
import {Dashboard} from "../Dashboard/Dashboard";
import {QrHome} from "../GenericQrCodeComponents/QrHome";
import {NotFound} from "../Utils/NotFound";
import {AboutUs} from "../AboutUs/AboutUs";
import PrivacyPolicy from "../Utils/PrivacyPolicy";
import {SmartSignIn} from "../Authentication/SmartSignIn";
import SetupInterceptors from "../../Utils/Auth/SetupInterceptors";
import {QrCodeProjects} from "../SmartQrCodePage/QrProjectTable/QrCodeProjects";
import {QrCodeProjectDashboard} from "../SmartQrCodePage/QrProjectDashboard/QrCodeProjectDashboard";
import EventPage from "../EventPage/EventPage";

function NavigateFunctionComponent(props:any) {
    let navigate = useNavigate();
    SetupInterceptors(navigate);
    return <></>;
}
// {design: "https://designs.ai/colors/palette/1226"}

const theme = extendTheme({
    initialColorMode: 'dark',
    colors: {
        brand: {
            100: "#2d4059",

            200: "#ea5455",

            300: "#f07b3f",
            310: "rgba(240,123,63,0.2)",

            400: "#ffd460",
        },
    },
})

export const App = () => {

    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => localStorage.setItem("ip4", data.ip));

    return(
      <ChakraProvider theme={theme}>
            <BrowserRouter>
               {<NavigateFunctionComponent />}
                <Routes>
                    <Route path="/ab" >
                        <Route path="/abScanner" element={<EventPage/>}/>
                        <Route path="/abScannedResult" element={<EventPage/>}/>
                    </Route>
                    <Route path="/" element={<Dashboard></Dashboard>}>
                        <Route path="/signin" element={<SmartSignIn/>}/>
                        <Route path="/signup" element={<SmartSignIn/>}/>
                    </Route>

                    <Route>
                        <Route path="/h" element={<Dashboard></Dashboard>}/>
                    </Route>

                    <Route path="/" element={<Dashboard></Dashboard>}>
                        <Route path="/home" element={<QrHome></QrHome>}/>
                        <Route path="/SmartQrCode" element={<QrCodeProjects/>}/>
                            <Route path="/SmartQrCode/:project_id" element={<QrCodeProjectDashboard/>}/>
                                <Route path="/SmartQrCode/:project_id/:qr_code_id" element={<QrHome/>}/>

                        <Route path="/about" element={<AboutUs/>}/>
                        <Route path="/privacy" element={<PrivacyPolicy/>}/>
                    </Route>

                    <Route path="*" element={<NotFound/>}/>

                </Routes>
            </BrowserRouter>
      </ChakraProvider>
)}
