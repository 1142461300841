import {
    Button, Progress,
    FormControl, FormLabel, HStack, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Spacer,
    useToast, VStack, Box
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {User} from "../../../Models/User";
import {QrProject} from "../../../Models/QrProject";
import {UsersAPI} from "../../../APIs/UsersAPI";
import LocalStorageService from "../../../Utils/Auth/LocalStorageService";
import {ObjectID, ObjectId} from "bson";
import {QrProjectsAPI} from "../../../APIs/QrProjectsAPI";
import * as React from "react";
import {readBlobText} from "@testing-library/user-event/utils/dataTransfer/Blob";
import {QrCode} from "../../../Models/QrCode";
import {QrCodesAPI} from "../../../APIs/QrCodesAPI";
import {useParams} from "react-router-dom";
import {YazanValidation} from "../../../Utils/validation";

interface Props {
    run: any,
    isOpen: any,
    onClose: any
}

export const ShareProjectModal = ({run, isOpen, onClose}: Props) => {

    let { project_id } = useParams();

    const toast = useToast()
    const [user, setUser] = useState<User>();
    const [qrProject, setQrProject] = useState<QrProject>();
    const [userEmail, setUserEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const get_user = async () => {
        setUser(await UsersAPI.get_user_by_id(LocalStorageService.getInstance().getUser()).then())
    }

    const get_project = async () => {
        setQrProject(await QrProjectsAPI.get_qr_project_by_id(new ObjectId(project_id!)).then())
    }

    const clear_email = () => {
        setLoading(false)
        setUserEmail("")
    }

    const required_info = () => {
        if (userEmail == "" || !YazanValidation.validateEmail(userEmail)) {
            return false
        } else {
            return true
        }
    }

    const share_project = () => {
        if (required_info()) {
            setLoading(true)
            QrProjectsAPI.share_qr_project(qrProject!, userEmail)
                .then((res) => {
                    let data = res!
                    toast({
                        title: 'Success.',
                        description: data.data,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    onClose()
                })
                .catch((err) => {
                    setLoading(false)
                    toast({
                        title: 'Error.',
                        description: err.response.data,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        } else {
            toast({
                title: 'Error.',
                description: "Qr Code creation failed!",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const close_modal = () => {
        setLoading(false)
        clear_email()
        onClose()
    }

    useEffect(() => {
        get_user().then()
        get_project().then()
    }, [])

    useEffect(() => {
        clear_email()
    }, [user])

    return(
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Share "{qrProject?.name}"</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack>
                            <FormControl
                                id="email"
                            >
                                <FormLabel>Share with</FormLabel>
                                <Input
                                    placeholder='john.smith@example.com'
                                    type="email"
                                    isRequired
                                    value={userEmail}
                                    onChange={(value) => {
                                        setUserEmail(value.target.value)
                                    }}
                                    autoFocus={true}/>
                            </FormControl>
                            <Spacer/>
                            { loading ?
                                <Box width={"100%"}>
                                    <Progress
                                        isIndeterminate={loading}
                                        colorScheme={"red"}
                                    />
                                </Box>
                                :
                                <></>
                            }
                        </VStack>
                    </ModalBody>

                    <ModalFooter>

                        <VStack>
                            <HStack>
                                <Spacer/>
                                <Button
                                    onClick={close_modal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    bgGradient="linear(to-r, brand.300, brand.200)"
                                    isDisabled={!required_info()}
                                    variant='solid'
                                    onClick={share_project}
                                    isLoading={loading}
                                    _hover={{
                                        bg: "brand.200",
                                    }}
                                >
                                    Share
                                </Button>
                            </HStack>
                        </VStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}