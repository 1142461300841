import FLASK_HTTPS from "./FLASK_API";
import {ObjectID} from "bson";
import {QrCode, QrCodeSingleLog} from "../Models/QrCode";
import {QrProject} from "../Models/QrProject";

export namespace QrCodesAPI {

    let route_name = "/qr_codes"

    export const get_qr_code_by_id = async (qr_code_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/get_qr_code_by_id/" + qr_code_id.toString())
            .then((res) => {
                return res.data as QrCode
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_qr_codes_by_project_id = async (page_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/get_qr_codes_by_project_id/" + page_id.toString())
            .then((res) => {
                return res.data as Array<QrCode>
            }).catch((res) => {
                console.log(res)
            })
    }

    export const delete_qr_code_by_id = async (qr_code_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/delete_qr_code_by_id/" + qr_code_id.toString())
            .then((res) => {
                return res.data as boolean
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const create_qr_code = async (qr_code: QrCode) => {
        return FLASK_HTTPS.post(route_name + "/create_qr_code", qr_code)
            .then((res) => {
                return res.data as QrCode
            }).catch((res) => {
                console.log(res)
            })
    }

    export const update_qr_code = async (qr_code: QrCode) => {
        return FLASK_HTTPS.post(route_name + "/update_qr_code", qr_code)
            .then((res) => {
                return res.data as QrCode
            }).catch((res) => {
                console.log(res)
            })
    }

    export const log_qr_data = async (qr_code_log: QrCodeSingleLog) => {
        return FLASK_HTTPS.post(route_name + "/log_qr_data", qr_code_log)
            .then((res) => {
                return res.data as ObjectID
            }).catch((res) => {
                console.log(res)
            })
    }

}
