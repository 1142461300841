import {
    Box,
    Heading,
    Center,
    Spacer,
    VStack,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    HStack,
    LightMode,
    SimpleGrid,
    Flex

} from '@chakra-ui/react';
import * as React from "react";
import {DownloadIcon, EmailIcon} from "@chakra-ui/icons";
import QRCode from "qrcode.react";
import {useEffect, useState} from "react";
import {ObjectId, ObjectID} from "bson";
import AdsComponent from "../Ads/Ad1";
import {QrSelector} from "./QrSelector";
import {QrCodeView} from "./QrCodeView";
import {QrCode, QrCodeSingleLog} from "../../Models/QrCode";
import {QrCodesAPI} from "../../APIs/QrCodesAPI";

interface QrHomeProps {
    qrCode: QrCode,
    setQrCode: any,
    qrCodeDownload: any,
}

export const QrMobileView = ({qrCode, setQrCode, qrCodeDownload}: QrHomeProps) => {


    return (
        <>
            <Center>
                <VStack
                    spacing={10}
                    align='stretch'
                >
                    <Box>
                        <Heading
                            display="inline-block"
                            as="h2"
                            size="md"
                            bgGradient="linear(to-r, brand.300, brand.200)"
                            backgroundClip="text">
                            Text To Qr Code Generator
                        </Heading>
                    </Box>
                    <Box>
                        <SimpleGrid  columns={[null, 1]} spacing='20px'>
                            <QrCodeView
                                qrCode={qrCode!}
                                setQrCode={setQrCode}
                                qrCodeDownload={qrCodeDownload}
                            />

                            <QrSelector
                                qrCode={qrCode!}
                                setQrCode={setQrCode}
                                qrCodeDownload={qrCodeDownload}
                            />
                        </SimpleGrid>
                    </Box>
                    {/*<AdsComponent/>*/}
                </VStack>
            </Center>
        </>
    );
}
