import FLASK_HTTPS from "./FLASK_API";
import {ObjectID} from "bson";
import {Attendee} from "../Models/Attendee";

export namespace AbEventAPI {

    let route_name = "/ab_event"

    export const get_attendee_by_id = async (attendee_id: ObjectID | string) => {
        return FLASK_HTTPS.get(route_name + "/get_attendee_by_id/" + attendee_id.toString())
            .then((res) => {
                return res.data as Attendee
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const check_in_attendee_by_id = async (attendee_id: ObjectID | string, checked_in: boolean) => {
        return FLASK_HTTPS.post(route_name + "/check_in_attendee_by_id/" + attendee_id.toString(), {
            checked_in
        })
            .then((res) => {
                return res.data.status as boolean
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_attendees = async () => {
        return FLASK_HTTPS.get(route_name + "/get_attendees")
            .then((res) => {
                return res.data as Array<Attendee>
            }).catch((res) => {
                console.log(res)
            })
    }
}
