import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
  LinkBox,
  LinkOverlay,
  Box
} from '@chakra-ui/react'
import * as React from 'react'


interface Column {
  Header: string;
  accessor: string;
  Cell?: any | undefined;
}

interface UserProps {
  columns: Array<Column>,
  data: Array<any>
  hover_color?: string
  disable_hover?: boolean
}


export const CustomTable1 = ({columns, data, hover_color, disable_hover}: UserProps) => {
  let hover_style: any;
  if(hover_color && !disable_hover){
    hover_style = {
      bg: hover_color,
    }
  }
  else{
    hover_style = {
    }
  }
  return (
      <Table my="8" borderWidth="1px" fontSize="sm">
        <Thead bg={mode('gray.50', 'gray.800')}>
          <Tr>
            {columns.map((column, index) => (
                <Th scope="col" key={index}>
                  {column.Header}
                </Th>
            ))}
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
              <Tr
                  key={index}
                  _hover={hover_style}
                  bgColor={row.selected ? hover_color : undefined}
                  onClick={row.run_func ? row.run_func : () => {
                    if (row.link) {
                      window.open(row.link,"_self")
                    }
                  }}
              >
                {columns.map((column, index) => {
                  const cell = row[column.accessor as keyof typeof row]
                  const element = column.Cell?.(cell, row) ?? cell
                  return (
                      <Td whiteSpace="nowrap" key={index}>
                        {element}
                      </Td>
                  )
                })}

                <Td textAlign="right">
                  <Box></Box>
                </Td>
              </Tr>
          ))}
        </Tbody>
      </Table>
  )
}
