import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader, Flex,
    FormControl,
    FormLabel,
    Heading, HStack, IconButton, Popover, PopoverArrow,
    PopoverCloseButton, PopoverContent, PopoverTrigger, Select, Spacer, Stack, Text,
    Textarea, useDisclosure, useToast,
    VStack
} from "@chakra-ui/react";
import * as React from "react";
import {Form, useParams} from "react-router-dom";
import {ColorPicker} from "../Utils/ColorPicker";
import {BiSave, FaLine} from "react-icons/all";
import {CheckIcon, CloseIcon, SettingsIcon} from "@chakra-ui/icons";
import {QrCode} from "../../Models/QrCode";
import {useEffect, useState} from "react";
import {QrCodesAPI} from "../../APIs/QrCodesAPI";

interface QrSelectorProps {
    qrCode: QrCode
    setQrCode: any
    qrCodeDownload: any,
}

export const QrSelector = ({qrCode, setQrCode, qrCodeDownload}: QrSelectorProps) => {

    let {qr_code_id} = useParams();
    const toast = useToast()

    const [qrCodeCopy, setQrCodeCopy] = useState<QrCode>(qrCode!);

    const qrCodeUpdate = (name: string, value: any) => {
        setQrCode(
            (prevState: any) =>
                ({...prevState,
                    [name]: value})
        );
    }

    const isQrCodeChanged = (): boolean => {
        return (qrCode.value != qrCodeCopy.value) || (qrCode.errorCorrectionLevel != qrCodeCopy.errorCorrectionLevel) ||
            (qrCode.bgColor != qrCodeCopy.bgColor) || (qrCode.qrColor != qrCodeCopy.qrColor) ||
            (qrCode.name != qrCodeCopy.name)
    }

    const reset_qr_code = () => {
        setQrCode(qrCodeCopy)
    }

    const update_qr_code = () => {

        QrCodesAPI.update_qr_code(qrCode)
            .then((res) => {
                let data = res!
                setQrCode(data)
                setQrCodeCopy(data)
                toast({
                    title: 'Success.',
                    description: "Changes Saved Successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            })
            .catch((err) => {
                toast({
                    title: 'Error.',
                    description: "Changes Failed to Save!",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                console.log(err.toString())
            })


    }

    useEffect(() => {
        if (qrCode._id != qrCodeCopy._id) {
            setQrCodeCopy(qrCode)
        }
    }, [qrCode])

    return(
        <>
            <Box flex={1}>
                <Card
                    height={"450px"}
                    width={"100%"}
                >

                    <CardHeader>
                        <Heading size='md'> QR Creator</Heading>
                    </CardHeader>
                    <CardBody>
                        <VStack>
                            <FormControl>
                                <FormLabel>
                                    Write your text here
                                </FormLabel>
                                <Textarea
                                    placeholder={"..."}
                                    focusBorderColor={"brand.200"}
                                    value={qrCode.value}
                                    height={"200px"}
                                    id={"value"}
                                    onChange={(res) => {
                                        qrCodeUpdate(res.target.id, res.target.value)
                                    }
                                    }/>
                            </FormControl>
                            <HStack>
                                <Box>
                                    <VStack>
                                        <HStack>
                                            <Text>Bg Color</Text>
                                            <ColorPicker
                                                id={"bgColor"}
                                                color={qrCode.bgColor}
                                                onChange={qrCodeUpdate}
                                            />
                                        </HStack>
                                        <HStack>
                                            <Text>Qr Color</Text>
                                            <ColorPicker
                                                id={"qrColor"}
                                                color={qrCode.qrColor}
                                                onChange={qrCodeUpdate}
                                            />
                                        </HStack>
                                    </VStack>
                                </Box>
                                <Spacer/>
                                <Box>
                                    <FormControl>
                                        <FormLabel>
                                            Error Correction Level
                                        </FormLabel>
                                        <Select
                                            id={"errorCorrectionLevel"}
                                            value={qrCode.errorCorrectionLevel}
                                            onChange={(res) => {
                                                qrCodeUpdate(res.target.id, res.target.value)
                                            }}
                                        >
                                            <option value='L'>L: Low (%7)</option>
                                            <option value='M'>M: Medium (%15)</option>
                                            <option value='Q'>Q: Quartile (%25)</option>
                                            <option value='H'>H: High (%30)</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </HStack>
                            { isQrCodeChanged() && qr_code_id
                                ? <HStack>
                                    <IconButton
                                        aria-label={"save"}
                                        size={"sm"}
                                        colorScheme='green'
                                        onClick={update_qr_code}
                                    >
                                        <CheckIcon/>
                                    </IconButton>
                                    <IconButton
                                        aria-label={"cancel"}
                                        size={"sm"}
                                        colorScheme='red'
                                        onClick={reset_qr_code}
                                    >
                                        <CloseIcon/>
                                    </IconButton>

                                </HStack>

                                : <>
                                </>
                            }
                        </VStack>

                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </Box>
        </>
    )
}