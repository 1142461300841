import {
    Box,
    Heading,
    Center,
    Input,
    Spacer,
    VStack,
    Stack,
    CircularProgress,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text, HStack, LightMode, SimpleGrid, Flex

} from '@chakra-ui/react';
import {FaDiscord, FaGithub} from "react-icons/all";
import * as React from "react";
import {QrDesktopView} from "./QrDesktopView";
import {QrMobileView} from "./QrMobileView";
import {BrowserView, MobileView} from 'react-device-detect';
import {useEffect, useState} from "react";
import {QrCode, QrCodeSingleLog} from "../../Models/QrCode";
import {ObjectID, ObjectId} from "bson";
import {QrCodesAPI} from "../../APIs/QrCodesAPI";
import {useParams} from "react-router-dom";

export const QrHome = () => {

    let { qr_code_id } = useParams();
    let { project_id } = useParams();

    const [isQrInsideProject, setIsQrInsideProject] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<QrCode>({
            _id: new ObjectId(),
            name: "",
            value: "",
            bgColor: "white",
            qrColor: "black",
            errorCorrectionLevel: "H",

        }
    );

    const check_if_qr_code_belongs_to_project = () => {
        if (project_id && qr_code_id) {
            if (ObjectId.isValid(project_id) && ObjectId.isValid(qr_code_id)) {
                QrCodesAPI.get_qr_code_by_id(new ObjectId(qr_code_id))
                    .then((res) => {
                        let date = res!
                        setQrCode(date)
                        setIsQrInsideProject(true)
                    })
            } else {
                setIsQrInsideProject(false)
            }
        } else {
            setIsQrInsideProject(false)
        }
    }

    const qrCodeDownload = async () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById(qrCode!._id.toString());
        // @ts-ignore
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `logo.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const resetQrCode = () => {
        setQrCode({
            _id: new ObjectID(),
            name: "",
            value: "",
            bgColor: "",
            qrColor: "",
            errorCorrectionLevel: "",
        })
    }

    useEffect(() => {
        check_if_qr_code_belongs_to_project()
    }, [])

    useEffect(() => {
        if (qrCode.value != "") {
            let qr_data_log: QrCodeSingleLog = {
                ip4: localStorage.getItem("ip4")!,
                value: qrCode.value
            }
            QrCodesAPI.log_qr_data(qr_data_log).then()
        }

    }, [qrCode.value]);

    return (
        <>
            <MobileView>
                <QrMobileView qrCode={qrCode} qrCodeDownload={qrCodeDownload} setQrCode={setQrCode}/>
            </MobileView>
            <BrowserView>
                <QrDesktopView qrCode={qrCode} qrCodeDownload={qrCodeDownload} setQrCode={setQrCode} isQrInsideProject={isQrInsideProject}/>
            </BrowserView>
        </>
    );
}
