import {
    Box,
    Heading,
    Center,
    Spacer,
    VStack,
    Textarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    HStack,
    LightMode,
    SimpleGrid,
    Flex

} from '@chakra-ui/react';
import * as React from "react";
import {DownloadIcon, EmailIcon} from "@chakra-ui/icons";
import QRCode from "qrcode.react";
import {useEffect, useState} from "react";
import {ObjectId, ObjectID} from "bson";
import AdsComponent from "../Ads/Ad1";
import {QrSelector} from "./QrSelector";
import {QrCodeView} from "./QrCodeView";
import {QrCode, QrCodeSingleLog} from "../../Models/QrCode";
import {QrCodesAPI} from "../../APIs/QrCodesAPI";
import {useNavigate, useParams} from "react-router-dom";
import {IoIosArrowBack} from "react-icons/all";

interface QrHomeProps {
    qrCode: QrCode,
    setQrCode: any,
    qrCodeDownload: any,
    isQrInsideProject: boolean
}

export const QrDesktopView = ({qrCode, setQrCode, qrCodeDownload, isQrInsideProject}: QrHomeProps) => {

    let navigate = useNavigate();

    const get_heading = () => {
        if (isQrInsideProject) {
            return (
                <>
                    {qrCode.name + " Qr Code"}
                </>)
        } else {
            return "Text To Qr Code Generator"
        }
    }

    return (
        <>
            <Center>
                <VStack spacing={10} align='stretch'>
                    <Box>
                        { isQrInsideProject
                            ? <>
                                <HStack>
                                    <Button
                                        leftIcon={<IoIosArrowBack />}
                                        variant='solid'
                                        onClick={() => {
                                            navigate("/SmartQrCode/" + qrCode.project_id)
                                        }}
                                    >
                                        Qr Codes Page
                                    </Button>
                                </HStack>
                                <Spacer height={"10px"}/>
                            </>
                            :
                            <>
                            </>
                        }
                        <Heading
                            display="inline-block"
                            as="h2"
                            size="lg"
                            fontFamily={""}
                            bgGradient="linear(to-r, brand.300, brand.200)"
                            backgroundClip="text">
                            {get_heading()}
                        </Heading>
                    </Box>
                    <Spacer></Spacer>
                    <Box>
                        <SimpleGrid  columns={[null, 1, 2]} spacing='20px'>
                            <QrSelector
                                qrCode={qrCode!}
                                setQrCode={setQrCode}
                                qrCodeDownload={qrCodeDownload}
                            />

                            <QrCodeView
                                qrCode={qrCode!}
                                setQrCode={setQrCode}
                                qrCodeDownload={qrCodeDownload}
                            />
                        </SimpleGrid>
                    </Box>
                    {/*<AdsComponent/>*/}
                </VStack>
            </Center>
        </>
    );
}
